      /* core */
      html,
      body {
        height: 100%;
        width: 100%;
      }
      body {
        font-family: Ubuntu, sans-serif;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }

      .wrapper {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 1.3rem;
      }

      .wrapper > div {
        padding: 20px 20%;
      }

      .error {
        background: #c487d3;
        color: #fff;
        font-weight: 600;
        font-size: 1.6rem;
        text-align: center;
        margin: 0;
        width: 100%;
        height: 100%;
      }

      .error p {
        padding-top: 10%;
        margin: 0;
      }

      .error-image {
        margin-top: 60px;
        width: 300px;
        height: auto;
      }

      /* sections */
      /* header */
      .header {
        background: #c487d3;
        min-height: 60px;
        color: white;
      }

      .navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .hero {
        display: flex;
      }

      .hello {
        align-self: center;
      }

      hr {
        margin: 0 auto;
        margin-top: 40px;
        width: 70%;
        border: 0;
        height: 2px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(54, 1, 77), rgba(255, 255, 255, 0))
      }

      /* content */
      .content {
        color: #36014d;
        background-color: #ffffff;
        align-self: center;
      }

      .center {
        text-align: center;
      }

      .intro, .description {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }

      .block:nth-child(odd) {
        padding-right: 60px;
      }

      .block:nth-child(even) {
        padding-left: 60px;
      }

      .companies {
        display: flex;
        justify-content: space-between;
        padding: 40px 10px;
      }

      .contact {
        text-align: center;
      }

      .post {
        padding: 20px 20%;
      }

      .text {
        // padding: 5px 10px;
      }

      .post-list {
        padding-left: 20px;
      }

      .post-list li {
        margin-bottom: 15px;
      }

      .post-meta {
        color: gray;
        font-size: 0.85rem;
      }

      .post-content img {
        width: auto !important;
        max-width: 90% !important;
      }

      .recipe-content > ul {
        list-style-type: none;
        padding-left: 0;
      }

      .recipe-content > ol {
        padding-left: 25px;
      }

      iframe {
        width: 90%;
        height: auto;
      }

      .flexi-items {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 50px 0;
        margin: 0;

        .button {
          margin: 0;
        }
      }

      /* footer */
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        background-color: #af61c3;
        color: #ffffff;
        font-size: 1rem;
      }

      .footer p {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .footer .footer-contact, .footer .copyright {
        width: 310px;
      }

      .footer .footer-contact {
        display: flex;
        justify-content: flex-end;
      }

      .powered-by, .terms {
        font-size: 0.7rem;
      }

      /* images */
      .logo {
        width: 90px;
      }

      .company {
        margin-right: 20px;
        width: 80px;
        height: auto;
        align-self: center;
      }

      .portrait {
        width: 200px;
      }

      .portrait-lets-work {
        @extend .portrait;
        float: right;
        padding-left: 50px;
        padding-bottom: 50px;
      }

      .project {
        width: 320px;
        height: auto;
        float: right;
        margin-left: 30px;
        margin-bottom: 30px;
      }

      .doodle {
        width: 500px;
        z-index: 10000;
      }

      .polaroid {
        width: 300px;
        background: #f3f2f2;
        padding: 15px 15px 30px;
        // margin: 5px 20px;
        -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
      }

      img {
        width: 100%;
        max-width: 800px;
        border-radius: 1%;
      }

      .recipe-photos {
        display: flex;
        justify-content: space-around;
      }

      .recipe-photo {
        max-width: 540px;
      }

      .testimonial {
        text-align: right;
      }

      /* headings */
      h1 {
        font-size: 2rem;
      }

      .hero h1 {
        margin: 10px auto;
        font-size: 3rem;
      }

      h2 {
        font-size: 1.6rem;
      }

     .hero h2 {
        margin: 10px auto;
        font-size: 1.4rem;
      }

      h3 {
        font-size: 1.4rem;
      }

      h4 {
        margin: 10px auto;
        font-size: 1.3rem;
      }

      h5 {
        margin: 10px auto;
        font-size: 1.1rem;
      }

      /* icons */
      .icon {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin: 8px 12px;
        background-size: cover;
        filter: brightness(0) invert(1);
      }

      .i-big {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin: 8px 12px;
        background-size: cover;
        filter: brightness(0) invert(1);
      }

      .i-dark {
        filter: none;
      }

      .icon-mail {
        background-image: url(/assets/mail.svg);
      }
      .icon-linkedin {
        background-image: url(/assets/linkedin.svg);
      }
      .icon-github {
        background-image: url(/assets/github.svg);
      }
      .icon-back {
        background-image: url(/assets/back.svg);
      }

      /* links */
      a:link {
        color: #701c7f;;
      }

      a:visited {
        color: #701c7f;
      }

      a:hover {
        color: #6a257c;
      }

      a.button {
        text-decoration-line: none;
        text-align: center;
        color: #6a257c;
        margin-top: 60px;
        padding: 10px 12px;
        border-style: solid;
        border-radius: 9999px;
        border-width: 2px;
        border-color: #6a257c;
        display: block;
        width: auto;
        font-weight: 600;
        font-size: 1.1rem;
      }

      a.button:hover {
        text-decoration-line: none;
        color: #fff;
        background-color: #6a257c;
      }

      .hero .button {
        width: fit-content;
      }

      .navigation-links a{
        color: #fff;
        font-weight: 600;
        margin-left: 20px;
      }

      .footer a {
        color: #e2cde7;
      }

      .footer .logo{
        width: 50px;
      }

      .back-to-top {
        text-align: right;
      }

      /* code */
      .highlighter-rouge {
        max-width: 960px;
      }

      pre.highlight {
        white-space: pre;
        overflow-x: scroll;
        background-color: #e2cde7;
      }


      /* decorations */
      .highlighted {
        background: linear-gradient(
          to bottom,
          transparent 15%,
          #ffd633 60%,
        );
        display: inline;
      }

      // **************************************************

      /* Responsive layout */
      @media (max-width: 820px) {
        .polaroid {
          width: 200px;
        }

        .recipe-photos {
          flex-direction: column;
        }

        .recipe-photo {
          margin-top: 10px;
        }

        .highlighter-rouge {
          max-width: 560px;
        }

        .wrapper > div {
          padding: 20px 5%;
        }
      
        .post {
          padding: 20px 5%;
        }

        .doodle {
          width: auto;
          height: auto;
          max-width: 450px;
          align-self: flex-end;
        }

        .project {
          float: none;
          margin: 0;
        }

        .footer {
          flex-direction: column;
          text-align: center;
        }

        .footer .footer-contact {
          justify-content: center;
        }

        .hero {
          flex-direction: column;
        }

        .intro {
          flex-direction: column;
        }

        .description {
          flex-direction: column-reverse;
        }

        .companies {
          flex-direction: column;
          text-align: center;
        }

        .company {
          padding-top: 10px;
          margin-right: 0;
        }

        .block:nth-child(odd) {
          padding-right: 0;
        }

        .block:nth-child(even) {
          padding-left: 0;
        }
      }

      /* Responsive layout */
      @media (max-width: 420px) {
        .content {
          max-width: 350px;
        }

        .highlighter-rouge {
          max-width: 360px;
        }

        .portrait-lets-work {
          float: none;
          padding-bottom: 0;
        }

        .doodle {
          width: 320px;
          align-self: center;
        }

        .navigation-links {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          padding: 5px;
        }

        .flexi-items {
          flex-direction: column;
          align-items: center;
          gap: 50px;
        }
      }

      // **********************************************

      /*@media (prefers-color-scheme: dark) {
        .header {
          background: #000;
          color: #fff;
        }

        .doodle {
          -webkit-filter: invert(1);
          filter: invert(1);
        }
      }*/